.chosen {
	background-color: #074750;
	color: white;
	border: 1px solid #074750;
	border-radius: 10px;
}

.button {
	background-color: white;
	color: #074750;
	border: 1px solid #074750;
	border-radius: 10px;
}

.button__fixed {
	max-width: 200px;
}

.chosen:hover {
	background-color: #074750;
	color: white;
	border: 1px solid #074750;
	border-radius: 10px;
}

.button:hover {
	background-color: white;
	color: #074750;
	border: 1px solid #074750;
	border-radius: 10px;
}
