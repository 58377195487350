.footer {
	color: white;
	background: #074750;
}

.footer__logo {
	font-weight: 700;
	font-size: 24px;
	color: #fff;
}

.social {
	color: #fff !important;
}

.hr {
	border-bottom: 1px solid #fff;
}

.legal__text {
	font-size: 12px;
}

.list__container {
}

.list__container ul {
	margin: 18px 0 0 0;
	padding: 0;
	list-style-type: none;
}

.list__container ul li a {
	color: #fff;
	font-weight: 400;
	font-size: 12px;
	text-decoration: none;
}

.list__container ul li a:hover {
	text-decoration: underline;
}

.links__header {
	font-size: 16px;
	font-weight: 600;
	color: #fff;
}

.footer__link {
	color: white;
}

.list li {
	color: #fff;
	text-decoration: none;
	list-style: none;
}

.list li a {
	color: #fff;
	text-decoration: none;
	font-size: 16px;
	font-weight: 500;
}

.input_field {
	background-color: transparent;
	border: 1px solid #c1d11e;
	border-radius: 30px;
	padding: 13px 10px;
	width: 100%;
	color: white;
}

.input_field::placeholder {
	color: white;
}

.checkbox_text {
	font-size: 12px;
}

.checkbox_box {
	height: 20px;
}

.text {
	font-size: 16px;
}
