/*
  DESKTOP NAVIGATION
*/

.nav__container {
	display: flex;
}

.nav__logo {
	line-height: 60px;
	font-weight: 700;
	font-size: 24px;
	text-decoration: none !important;
	color: white;
	cursor: pointer;
}

.logo_dark {
	line-height: 60px;
	font-weight: 700;
	font-size: 24px;
	text-decoration: none !important;
	color: #29343e;
	cursor: pointer;
}

.nav__logo:hover {
	color: white;
	text-decoration: none;
}

.nav {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.nav ul {
	margin: 0 0 0 10%;
	list-style-type: none;
	display: flex;
	flex-grow: 1;
}

.nav ul li {
	display: flex;
	height: 100%;

	margin-left: 20px;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	font-size: 14px;
}

.nav ul li a {
	color: inherit;
	text-decoration: none;
	position: relative;
}

.nav ul li a::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: -6px;
	width: 0;
	height: 2px;
	background-color: #fff;
	transition: width 0.3s;
}

.nav ul li a:hover::after {
	width: 100%;
}

.nav .landing_nav ul li a:hover {
	text-decoration: underline;
	cursor: pointer;
}

.nav__submenu {
	margin-top: 15px;
	background: #f4f7fb;
	padding: 20px;
	display: none;
	position: absolute;
	left: 0;
	top: 60px;
	width: 100%;
	z-index: 999;
	-webkit-box-shadow: 0 6px 10px -2px rgb(0 0 0 / 20%);
	box-shadow: 0 6px 10px -2px rgb(0 0 0 / 20%);
	border: 1px solid rgb(0 0 0 / 20%);
	cursor: default;
}

.nav ul li:hover .nav__submenu {
	display: block;
}

.call_button {
	border: 1px solid #fff;
	margin-top: 10px;
	padding: 8px 10px;
}
